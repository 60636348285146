import * as React from "react"
import { useState, useRef, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"

type QAItem = {
    question: string
    answer: string
}

interface AccordionProps {
    items: QAItem[]
    questionFont: any
    answerFont: any
    questionColor: string
    answerColor: string
    styles: {
        borderColor: string
        borderWidth: number
        borderRadius: number
        backgroundColor: string
        questionBackgroundColor: string
        iconColor: string
        iconSize: number
    }
    animationSpeed: number
}

export default function Accordion(props: AccordionProps) {
    const {
        items,
        questionFont,
        answerFont,
        questionColor,
        answerColor,
        styles,
        animationSpeed,
    } = props
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null)
    const [heights, setHeights] = useState<number[]>([])

    const refs = useRef<(HTMLDivElement | null)[]>([])

    useEffect(() => {
        const calculatedHeights = refs.current.map(
            (ref) => ref?.scrollHeight || 0
        )
        setHeights(calculatedHeights)
    }, [items])

    const toggleItem = (index: number) => {
        setExpandedIndex(expandedIndex === index ? null : index)
    }

    return (
        <div style={accordionStyle}>
            {items.map((item, index) => (
                <div
                    key={index}
                    style={{
                        ...containerStyle,
                        borderColor: styles.borderColor,
                        borderWidth: styles.borderWidth,
                        borderRadius: styles.borderRadius,
                        backgroundColor: styles.backgroundColor,
                        borderStyle: "solid",
                    }}
                >
                    <div
                        style={{
                            ...questionStyle,
                            color: questionColor,
                            backgroundColor: styles.questionBackgroundColor,
                            ...questionFont,
                        }}
                        onClick={() => toggleItem(index)}
                    >
                        {item.question}
                        <motion.div
                            animate={{
                                rotate: expandedIndex === index ? 45 : 0,
                            }}
                            transition={{
                                duration: animationSpeed,
                                ease: "easeInOut",
                            }}
                            style={iconStyle}
                        >
                            <svg
                                width={styles.iconSize}
                                height={styles.iconSize}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 5V19"
                                    stroke={styles.iconColor}
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5 12H19"
                                    stroke={styles.iconColor}
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </motion.div>
                    </div>
                    <motion.div
                        initial={false}
                        animate={{
                            height:
                                expandedIndex === index ? heights[index] : 0,
                        }}
                        transition={{
                            duration: animationSpeed,
                            ease: "easeInOut",
                        }}
                        style={{ overflow: "hidden" }}
                    >
                        <div
                            ref={(el) => (refs.current[index] = el)}
                            style={{
                                ...answerStyle,
                                color: answerColor,
                                ...answerFont,
                            }}
                        >
                            {item.answer}
                        </div>
                    </motion.div>
                </div>
            ))}
        </div>
    )
}

const accordionStyle: React.CSSProperties = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
}

const containerStyle: React.CSSProperties = {
    overflow: "hidden",
}

const questionStyle: React.CSSProperties = {
    padding: "16px",
    cursor: "pointer",
    userSelect: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}

const iconStyle: React.CSSProperties = {
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
}

const answerStyle: React.CSSProperties = {
    padding: "16px",
    userSelect: "none",
}

Accordion.defaultProps = {
    items: [
        { question: "Question 1", answer: "Answer 1" },
        { question: "Question 2", answer: "Answer 2" },
    ],
    questionFont: {
        fontSize: 16,
        lineHeight: "1.5em",
        fontFamily: "monospace",
    },
    answerFont: {
        fontSize: 14,
        lineHeight: "1.5em",
        fontFamily: "monospace",
    },
    questionColor: "#000",
    answerColor: "#000",
    styles: {
        borderColor: "#ccc",
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: "#fff",
        questionBackgroundColor: "#f7f7f7",
        iconColor: "#000",
        iconSize: 24,
    },
    animationSpeed: 0.5,
}

addPropertyControls(Accordion, {
    items: {
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.Object,
            controls: {
                question: { type: ControlType.String, title: "Question" },
                answer: { type: ControlType.String, title: "Answer" },
            },
        },
        title: "Questions and Answers",
        defaultValue: [
            { question: "Question 1", answer: "Answer 1" },
            { question: "Question 2", answer: "Answer 2" },
        ],
    },
    questionFont: {
        //@ts-ignore
        type: ControlType.Font,
        controls: "extended",
        displayFontSize: true,
        displayTextAlignment: false,
        defaultFontType: "monospace",
        title: "Question Font",
    },
    answerFont: {
        //@ts-ignore
        type: ControlType.Font,
        controls: "extended",
        displayFontSize: true,
        displayTextAlignment: false,
        defaultFontType: "monospace",
        title: "Answer Font",
    },
    questionColor: {
        type: ControlType.Color,
        title: "Question Color",
        defaultValue: "#000",
    },
    answerColor: {
        type: ControlType.Color,
        title: "Answer Color",
        defaultValue: "#000",
    },
    styles: {
        type: ControlType.Object,
        title: "Styles",
        controls: {
            borderColor: {
                type: ControlType.Color,
                title: "Border Color",
                defaultValue: "#ccc",
            },
            borderWidth: {
                type: ControlType.Number,
                title: "Border Width",
                defaultValue: 1,
                min: 0,
                max: 10,
            },
            borderRadius: {
                type: ControlType.Number,
                title: "Border Radius",
                defaultValue: 4,
                min: 0,
                max: 50,
            },
            backgroundColor: {
                type: ControlType.Color,
                title: "Background Color",
                defaultValue: "#fff",
            },
            questionBackgroundColor: {
                type: ControlType.Color,
                title: "Question Background Color",
                defaultValue: "#f7f7f7",
            },
            iconColor: {
                type: ControlType.Color,
                title: "Icon Color",
                defaultValue: "#000",
            },
            iconSize: {
                type: ControlType.Number,
                title: "Icon Size",
                defaultValue: 24,
                min: 10,
                max: 50,
            },
        },
    },
    animationSpeed: {
        type: ControlType.Number,
        title: "Animation Speed",
        defaultValue: 0.5,
        min: 0.1,
        max: 5,
        step: 0.1,
    },
})
